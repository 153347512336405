import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import Header from '../components/Header';
import '../styles/index.scss';
import logo from '../images/logo.png';
import comingSoon from '../images/coming-soon.png';

// markup
const IndexPage = () => {
  return (
    <>
      <title>Home | Curious Hedgehog</title>
      <Header />
      <main>
        <div className="container">
          <div className="coming-soon">
            <img
              className="coming-soon__featured"
              src={comingSoon}
              width="320"
            />
            <h2 className="coming-soon__title">Coming Soon</h2>
            <div className="coming-soon__description">
              A place to explore, discover and have fun!
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default IndexPage;
